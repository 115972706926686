import {useQuery } from '@tanstack/react-query';
import apiClient  from '../../ApiClient';

const getNotification = async () => {
  const res = await apiClient.get("notifications");
  return res.data;
};

export function useNotification() {
  return useQuery({
    queryKey: ['all_notification'],
    queryFn: getNotification,
    staleTime: 1000 * 60 * 1, 
  });
}