import React from "react";
import { LuX } from "react-icons/lu";

type Contact = {
  id?: number;
  name?: string[];
  tel?: string[];
  email?: string[];
};

type ContactCardProps = {
  contact: Contact;
  onDelete: (id: number) => void;
};

const ContactCard = ({ contact, onDelete }: ContactCardProps) => (
  <div className="relative bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 flex items-center space-x-4">
    <div className="flex-shrink-0">
      <div className="h-12 w-12 bg-purple-500 text-white flex items-center justify-center rounded-full text-lg font-semibold">
        {contact.name?.[0]?.charAt(0) || "?"}
      </div>
    </div>
    <div className="flex-1">
      <h3 className="text-md font-medium text-gray-900 dark:text-gray-100">
        {contact.name?.join(" ") || "Unknown Name"}
      </h3>
      {!!contact.tel?.[0] && (
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {contact.tel?.[0] || "No phone number"}
        </p>
      )}
      {!!contact.email?.[0] && (
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {contact.email?.[0] || "No email address"}
        </p>
      )}
    </div>

    <button
      onClick={() => onDelete(contact?.id ?? 0)}
      className="text-honeConnectOrange absolute top-6 p-2 right-1 text-xl dark:disabled:text-gray-200 disabled:text-gray-600"
      disabled={!contact?.id}
    >
      <LuX />
    </button>
  </div>
);

export default ContactCard;
