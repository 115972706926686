import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const MySwal = withReactContent(Swal);

// tiny-alert-export
export function tinyAlert(type: string = "close", message: string = "") {
  toast.dismiss();
  switch (type) {
    case "info":
      toast.info(message, {
        autoClose: 70000,
      });
      break;
    case "success":
      toast.success(message, {
        autoClose: 70000,
      });
      break;
    case "warning":
      toast.warning(message, {
        autoClose: 70000,
      });
      break;
    case "error":
      toast.error(message, {
        autoClose: 70000,
      });
      break;
    case "close":
      toast.dismiss();
      break;
    default:
      toast.error(message, {
        autoClose: 70000,
      });
  }
}
export function closeAlert() {
  MySwal.close();
}
export function errorAlert(message?: string, timer: number = 2000) {
  MySwal.fire({
    icon: "error",
    // title: "",
    timer: timer,
    timerProgressBar: false,
    html: `<small style="color:#f27474; font-size:12px">${message ?? <></>}</small>`,
    showDenyButton: false,
    showConfirmButton: false,
    background: "transparent",
  });
}
export function successAlert(message?: string) {
  MySwal.fire({
    icon: "success",
    showConfirmButton: false,
    html: `<small style="font-size:12px">${message ?? ""}</small>`,
    timer: 2000, // Auto-close after 5 seconds
    background: "transparent",
  });
}
export function confirmAlert(message: string) {
  return MySwal.fire({
    html: `<small style="font-size:14px">${message}</small>`,
    icon: "info",
    showCancelButton: true,
    confirmButtonColor: "#0083ff !important",
    cancelButtonColor: "red",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  });
}
