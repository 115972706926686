// page type are declared here

import { z } from "zod";

const loginSchema = z.object({
  userID: z
    .string()
    .min(5, "User ID must be at least 5 characters long")
    .refine(
      (value) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || /^[^\s@]+$/.test(value),
      {
        message: "Valid email address or username is required.",
      }
    ),
  password: z.string().min(6, "The password too short."),
  isRemember: z.boolean().optional(),
});

const RegisterStepOneSchema = z
  .object({
    fullname: z.string().refine(
      (value) => {
        const parts = value.trim().split(/\s+/);
        return parts.length >= 2 && parts.every((part) => part.length > 0);
      },
      {
        message: "Fullname must contain both first name and last name.",
      }
    ),
    email: z.string().email("Valid email address is required."),
    password: z.string().min(6, "The password is too short."),
    confirm_password: z
      .string()
      .min(6, "The confirmation password is too short."),
    isEligible: z.boolean().refine((value) => value === true, {
      message: "You must check the eligibility box to proceed.",
    }),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: "Password and confirm password do not match.",
    path: ["confirm_password"], // Points to the field with the error
  });

const RegisterStepTwoSchema = z.object({
  username: z
    .string()
    .min(4, "UserID is too short.")
    .regex(
      /^[a-zA-Z0-9_]+$/,
      "UserID can only contain letters, numbers, and underscores."
    ),
  phone_number: z.string().min(6, "phone number is too short."),
  DOB: z.string().date("Enter a valid date of birth."),
  gender: z.string().min(1, "Select Gender"),
  country_id: z.string().min(1, "Select Country"),
  state_id: z.string().min(1, "Select State"),
  city_id: z.string().min(1, "Select City"),
  area: z.string().min(1, "Area is required"),
  isAgreedTerms: z.boolean().refine((value) => value === true, {
    message: "Agree our terms & privacy policy.",
  }),
});

const RegisterStepThreeSchema = z.object({
  instagram_id: z
    .string()
    .min(3, "valid id is required")
    .optional()
    .or(z.literal("")),
  snapchat_id: z
    .string()
    .min(3, "valid id is required.")
    .optional()
    .or(z.literal("")),
  twitter_x_id: z
    .string()
    .min(3, "valid id is required")
    .optional()
    .or(z.literal("")),
  interest: z.string().min(1, "Select interest"),
});

const UpdateUserInfoSchema = z.object({
  instagram_id: z.string().min(3, "valid id is required").optional(),
  bio: z.string().max(100, "valid id is required").optional(),
  snapchat_id: z.string().min(3, "valid id is required.").optional(),
  twitter_x_id: z.string().min(3, "valid id is required").optional(),
  interest: z.string().min(1, "Select interest"),
  username: z.string().min(4, "UserID is too short."),
  phone_number: z.string().min(6, "phone number is too short."),
  DOB: z.string().date("Enter a valid date of birth."),
  gender: z.string().min(1, "Select Gender"),
  country_id: z.string().min(1, "Select Country"),
  state_id: z.string().min(1, "Select State"),
  city_id: z.string().min(0, "Select City").optional(),
  area: z.string().min(0, "Area is required").optional(),

  full_name: z.string().refine(
    (value) => {
      const parts = value.trim().split(/\s+/);
      return parts.length >= 2 && parts.every((part) => part.length > 0);
    },
    {
      message: "Fullname must contain both first name and last name.",
    }
  ),
  email: z.string().email("Valid email address is required."),
});

export {
  loginSchema,
  RegisterStepOneSchema,
  RegisterStepTwoSchema,
  RegisterStepThreeSchema,
  UpdateUserInfoSchema,
};
export type loginType = z.infer<typeof loginSchema>;
export type RegisterStepOneType = z.infer<typeof RegisterStepOneSchema>;
export type RegisterStepTwoType = z.infer<typeof RegisterStepTwoSchema>;
export type RegisterStepThreeType = z.infer<typeof RegisterStepThreeSchema>;
export type UpdateUserInfoType = z.infer<typeof UpdateUserInfoSchema>;
