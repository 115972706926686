import WebsiteHeader from "./website-components/WebsiteHeader";
import WebsiteFooter from "./website-components/WebsiteFooter";

const TermsOfUse = () => {
  return (
    <main>
      <WebsiteHeader />
      {/* HERO SECTION */}
      <div className="bg-[#F8FAFC]" id="home">
        <div className="w-11/12 mx-auto py-10 md:py-16 md:pb-16 grid md:grid-rows-2 lg:grid-rows-none lg:grid-cols-2 gap-10">
          <div className="pr-0 lg:pr-20  lg:text-left">
            <h1 className="text-[30px] md:text-[55px] font-bold mb-5 md:mb-8 lg:mb-4 leading-tight text-left">
              Terms <span className="text-honeConnectPurple"> </span> And{" "}
              <span className="text-honeConnectPurple">Conditions</span>{" "}
            </h1>

            <div className="flex flex-col">
              <h1>Terms of Use</h1>

              <h2>1.0 Introduction</h2>
              <p>
                Hone Connect Ltd, a private company limited by shares,
                incorporated in the Federal Republic of Nigeria with
                registration number 7570392.
              </p>
              <p>
                By accessing or using Hone Connect Ltd's Services, you agree to
                be bound by this Terms of Use Agreement including our Privacy
                Policy, Safety Tips, or other supplementary terms, so it is
                important that you read this Agreement and these policies
                carefully before you create an account.
              </p>
              <p>
                By accessing or using our Services on{" "}
                <a href="https://honeconnect.com">honeconnect.com</a>, the Hone
                Connect mobile application, or any other platforms or services
                Hone Connect may offer, you agree to, and are bound by this
                Agreement. This Agreement applies to anyone who accesses or uses
                our Services, regardless of registration or subscription status.
              </p>
              <p>
                If you do not agree with any part of these Terms, or if you are
                not eligible or authorized to be bound by the Terms, then do not
                access or use the Service.
              </p>
              <p>
                We reserve the right to modify, amend, or change the Terms at
                any time. We may notify you of a change to the Terms via email
                or other means; however, you are responsible for regularly
                checking this page for any changes. Your continued access or use
                of our Services constitutes your ongoing consent to any changes,
                and as a result, you will be legally bound by the updated Terms.
                If you do not accept a change to the Terms, you must stop
                accessing or using our Services immediately.
              </p>

              <h2>2.0 Definitions</h2>
              <ul>
                <li>
                  <strong>2.1 Individual:</strong> Means a person (i.e., not a
                  corporate body, partnership, or business entity).
                </li>
                <li>
                  <strong>2.2 Users:</strong> Means individuals who use Hone
                  Connect Ltd. services.
                </li>
                <li>
                  <strong>2.3 Content:</strong> Means data uploaded by a user
                  including but not limited to; contact information, location
                  information, demographic information, multimedia (images,
                  audio recordings, video recordings, etc.), views, opinions,
                  and interests.
                </li>
              </ul>

              <h2>3.0 Important Disclaimers</h2>
              <p>
                <strong>
                  HONE CONNECT LTD HAS NO CONTROL OVER AND DOES NOT GUARANTEE
                  THE ACCURACY OF INFORMATION PROVIDED BY USERS. HONE CONNECT
                  LTD ASSUMES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR
                  ANOTHER USER OR THIRD PARTY POSTS, SENDS, RECEIVES, OR ACTS ON
                  THROUGH OUR SERVICES, NOR DOES HONE CONNECT LTD ASSUME ANY
                  RESPONSIBILITY FOR THE IDENTITY, INTENTIONS, LEGITIMACY, OR
                  VERACITY OF ANY USERS WITH WHOM YOU MAY COMMUNICATE WITH
                  THROUGH HONE CONNECT LTD.
                </strong>
              </p>
              <p>
                <strong>
                  YOU ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR YOUR
                  SAFETY AND YOU UNDERSTAND THAT YOU SHOULD MEET WITH OTHER
                  INDIVIDUALS ONLY IN SAFE PUBLIC PLACES. YOU ARE SOLELY
                  RESPONSIBLE FOR CONDUCTING DUE DILIGENCE OF ANY INDIVIDUAL
                  REQUESTING TO MEET. HONE CONNECT LTD DISCLAIMS ANY
                  RESPONSIBILITY FOR USER’S INTERACTION WITH ANY INDIVIDUAL.
                  HONE CONNECT LTD MAKES NO GUARANTEES AS TO THE NUMBER OF
                  ACTIVE USERS AT ANY TIME; USERS' ABILITY OR DESIRE TO
                  COMMUNICATE WITH OR MEET YOU, OR THE ULTIMATE COMPATIBILITY
                  WITH OR CONDUCT BY USERS YOU MEET THROUGH THE SERVICES.
                </strong>
              </p>
              <p>
                <strong>
                  YOU HEREBY RELEASE HONE CONNECT LTD, OUR OFFICES, EMPLOYEES,
                  AGENTS, AND SUCCESSORS FROM CLAIMS, DEMANDS, ANY AND ALL
                  LOSSES, DAMAGES, RIGHTS, AND ACTIONS OF ANY KIND INCLUDING
                  PERSONAL INJURIES, DEATH, AND PROPERTY DAMAGE, THAT IS EITHER
                  DIRECTLY OR INDIRECTLY RELATED TO OR ARISES FROM ANY
                  INTERACTIONS WITH OR CONDUCT OF ANY INDIVIDUAL USING THE
                  SERVICES.
                </strong>
              </p>
              <p>
                <strong>
                  HONE CONNECT LTD DOES NOT REPRESENT OR WARRANT THAT OUR
                  SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR FREE. HONE
                  CONNECT LTD DOES NOT REPRESENT OR WARRANT THAT ANY DEFECTS OR
                  ERRORS IN OUR SERVICES WILL BE DISCOVERED OR CORRECTED. HONE
                  CONNECT LTD DOES NOT REPRESENT OR WARRANT THAT ANY CONTENT OR
                  INFORMATION YOU OBTAIN ON OR THROUGH OUR SERVICES WILL BE
                  ACCURATE, COMPLETE, CURRENT OR APPROPRIATE FOR YOUR PURPOSES.
                </strong>
              </p>

              <h2>4.0 Account Eligibility</h2>
              <p>By creating an account you represent and warrant that:</p>
              <ul>
                <li>You are an individual at least 18 years old.</li>
                <li>
                  You are legally qualified to enter a binding contract with
                  Hone Connect Ltd.
                </li>
                <li>You are not prohibited by law from using our Services.</li>
                <li>You do not have more than one account on our Services.</li>
                <li>
                  You have not previously been removed from our Services, unless
                  you have our express written permission to create a new
                  account.
                </li>
                <li>
                  You have not committed, been convicted of, or pled no contest
                  to a felony or crime.
                </li>
              </ul>
              <p>By creating an account you agree that:</p>
              <ul>
                <li>You comply with these terms of use agreement.</li>
                <li>
                  You comply to check this page from time to time to ensure you
                  are aware of any changes.
                </li>
                <li>
                  You comply with all applicable laws, including without
                  limitation, privacy laws, intellectual property laws,
                  anti-spam laws, and regulatory requirements.
                </li>
                <li>
                  You will take reasonable measures to protect the security of
                  your login information.
                </li>
                <li>
                  You will not misrepresent your identity, age, or affiliations
                  with a person or entity.
                </li>
                <li>
                  You will not use our Services for any harmful, illegal, or
                  nefarious purpose.
                </li>
                <li>
                  You will not use the Services in a way that damages the
                  Services or interfere with, disrupt, or negatively affect the
                  platform, the servers, or our Services' networks.
                </li>
                <li>
                  You will not harass, bully, stalk, intimidate, assault,
                  defame, harm, or otherwise abuse or cause psychological harm.
                </li>
                <li>You will not post or share Prohibited Content.</li>
                <li>
                  You will not solicit money or other items of value from
                  another user, whether as a gift, loan, or form of
                  compensation.
                </li>
                <li>You will not use our Services for hook-up.</li>
                <li>
                  You will not use our Services in relation to fraud or other
                  similar practice.
                </li>
                <li>
                  You will not disclose private or proprietary information that
                  you do not have the right to disclose.
                </li>
                <li>
                  You will not copy, modify, transmit, distribute, or create any
                  derivative works from our content, or any copyrighted
                  material, images, trademarks, trade names, service marks, or
                  other intellectual property, content, or proprietary
                  information accessible through our Services without Hone
                  Connect Ltd's prior written consent.
                </li>
                <li>
                  You will not upload viruses or other malicious code or
                  otherwise compromise the security of our Services.
                </li>
              </ul>

              <h2>5.0 Content</h2>
              <h3>5.1 User Content</h3>
              <p>
                You acknowledge and agree that Your Content may be viewed by
                other users, and, notwithstanding these Terms, other users may
                share Your Content with third parties. By uploading Your
                Content, you represent and warrant to Hone Connect Ltd. that you
                have all necessary rights and licenses to do so and
                automatically grant Hone Connect Ltd. a license to use Your
                Content. You understand and agree that Hone Connect Ltd. has no
                obligation to display or review Your Content.
              </p>
            </div>
          </div>
        </div>
      </div>
      <WebsiteFooter />
    </main>
  );
};

export default TermsOfUse;
