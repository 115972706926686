//! app wrapper for dynamic-title
import React, { useEffect } from "react";
import { HeadProps, LayoutProps } from "./common/layout.types";

//! head component for page title
const Head: React.FC<HeadProps> = ({ title }) => {
  const isDarkMode = localStorage.getItem("theme") === "dark";

  useEffect(() => {
    document.title = title;
  }, [title]);
  
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [isDarkMode]);

  return null;
};

// ! layout to render both the page title and child component
const GlobalLayout = ({
  children,
  title = "Welcome to Honeconnect",
}: LayoutProps) => (
  <>
    <Head title={title} />
    {children}
  </>
);

export default GlobalLayout;
