// login component
import React, { FormEvent, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api-services/ApiClient";
import { storeUser } from "../../api-services/process";
import ImageCrop from "../../app-components/image-cropper/ImageCrop";
import Preloader from "../../app-components/preloader/Preloader";
import AuthLayout from "../../app-layouts/AuthLayout";
import { SITELINKS } from "../../app-routes/links";
import {
  RegisterStepThreeSchema,
  RegisterStepThreeType,
} from "../../app-schemas/auth.schema";
import { inputStyle } from "../../app-util/helpers";
import { successAlert } from "../../app-util/SweetAlert";
import { CameraIcon } from "../../assets/custom-icons";
import { HoneConnectLogo } from "../../assets/img";

const RegisterStepThree = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>("");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  // component-state
  const [registrationData, setRegistrationData] = useState<
    Partial<RegisterStepThreeType>
  >({});

  // use-state-form-errors
  const [errors, setErrors] = useState<
    Partial<Record<keyof RegisterStepThreeType, string>>
  >({});

  // handle-input-change
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any
    >
  ) => {
    const { name, value } = e.target;
    setRegistrationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleIconClick = () => {
    fileInputRef.current?.click();
  };

  const isValidImage = (file: File) => {
    const validTypes = ["image/png", "image/jpg", "image/jpeg"];
    return validTypes.includes(file.type);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file && isValidImage(file)) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target?.result as string);
      reader.readAsDataURL(file);

      await uploadFile(file);
    } else {
      alert("Please select a valid PNG, JPG, or JPEG image.");
      setPreviewImage(null);
    }
  };

  const compressImage = (file: File, quality = 0.7): Promise<Blob | null> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set canvas size to desired dimensions (optional resizing)
          const MAX_WIDTH = 800; // Resize width if image is too large
          const scaleSize = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleSize;

          // Draw the image on canvas
          ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Compress image to Blob (JPEG with reduced quality)
          canvas.toBlob(
            (blob) => resolve(blob),
            "image/jpeg", // or "image/webp" for web-friendly compression
            quality // Compression quality: 0.1 (low) to 1.0 (high)
          );
        };
      };
    });
  };

  const uploadFile = async (file: File) => {
    try {
      const compressedBlob = await compressImage(file, 0.7); // Adjust quality as needed
      if (!compressedBlob) throw new Error("Failed to compress image");

      const formData = new FormData();
      formData.append("profile_pix", compressedBlob, file.name);

      setPageLoading(true);
      await apiClient.post("upload-profile-pix", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent?.total || 1
          );

          setUploadProgress(percentCompleted);
        },
      });
    } catch (error) {
      alert(error); // Display error message from apiClient
      setUploadProgress(0); // Reset progress on failure
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  // handle-login-submit
  const handleSignUpStepThree = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});
    setServerError("");

    const result = RegisterStepThreeSchema.safeParse(registrationData);
    if (result.success) {
      try {
        setLoading(true);
        const data = {
          ...result.data,
          interest: result.data.interest.split(","),
        };

        const res = await apiClient.post("register-step-three", data);
        const user = res.data?.data?.user;

        successAlert();
        storeUser(user);

        navigate(SITELINKS.HOME);
      } catch (error: any) {
        console.log(error);
        setServerError(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    } else {
      const formattedErrors: Partial<
        Record<keyof RegisterStepThreeType, string>
      > = {};

      result.error.errors.forEach((err) => {
        if (err.path.length > 0) {
          const key = err.path[0] as keyof RegisterStepThreeType;
          formattedErrors[key] = err.message;
        }
      });

      setErrors(formattedErrors);
      console.log(formattedErrors);
    }
  };

  // Upload or process cropped image
  const handleCroppedImage = async (file: File) => {
    console.log(file);
    setUploadProgress(0);
    await uploadFile(file);
  };

  return (
    <AuthLayout>
      <section className="py-3 px-0 lg:col-span-3">
        <div className="text-sm font-semibold">
          <Link to="/" className="text-honeConnectPurple flex">
            {/* <LuChevronLeft className="text-xl" /> <span>Back</span> */}

            <img src={HoneConnectLogo} alt="tiny top" className="h-[35px]" />
          </Link>
        </div>

        {/* return to website link */}
        <div className="text-sm font-semibold flex justify-end">
          <p className="uppercase text-honeConnectGray hidden sm:block">
            Step 3/3
          </p>
        </div>

        {/* form section */}
        <div className="pt-8 lg:pt-8 pb-0 px-5 lg:px-28 xl:px-28 text-gray-600">
          <div>
            <h4 className="text-xl text-honeConnectBlack font-bold mb-2.5">
              Add Social Media
            </h4>
            <p className="text-xs mb-8 font-medium">
              To keep connected kindly update your
              <br />
              picture in the below fileds.
            </p>
          </div>

          <form
            onSubmit={handleSignUpStepThree}
            autoComplete="off"
            autoCorrect="off"
            className="flex flex-col gap-4"
          >
            <div className="flex flex-col text-sm font-medium">
              <label htmlFor="IG_id" className="mb-1.5">
                Instagram ID
                <span className="text-honeConnectOrange"> (optional)</span>
              </label>
              <input
                value={registrationData.instagram_id}
                onChange={handleInputChange}
                type="text"
                className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                placeholder="Enter your Instagram id"
              />
              <span className="text-red-600 text-[10px]">
                {errors.instagram_id}
              </span>
            </div>

            <div className="flex flex-col text-sm font-medium">
              <label htmlFor="snapID" className="mb-1.5">
                Snapchat ID
                <span className="text-honeConnectOrange"> (optional)</span>
              </label>
              <input
                value={registrationData.snapchat_id}
                onChange={handleInputChange}
                type="text"
                className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                placeholder="Enter your snapchat id"
              />
              <span className="text-red-600 text-[10px]">
                {errors.snapchat_id}
              </span>
            </div>

            <div className="flex flex-col text-sm font-medium">
              <label htmlFor="x_ID" className="mb-1.5">
                X ID
                <span className="text-honeConnectOrange"> (optional)</span>
              </label>
              <input
                value={registrationData.twitter_x_id}
                onChange={handleInputChange}
                type="text"
                className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                placeholder="Enter your X id"
              />
              <span className="text-red-600 text-[10px]">
                {errors.twitter_x_id}
              </span>
            </div>

            <div className="flex flex-col text-center justify-center text-sm font-medium">
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/png, image/jpg, image/jpeg" // Restrict file types
                onChange={handleFileChange}
              />

              <div className="flex justify-center mt-2 hidden">
                <img
                  src={previewImage || CameraIcon} // Show preview if available, otherwise CameraIcon
                  className="w-2/3 p-1 rounded-lg text-center border-2 border-dashed border-honeConnectOrange cursor-pointer"
                  onClick={handleIconClick}
                  alt="Upload Preview"
                />
              </div>

              <ImageCrop onImageSelect={handleCroppedImage} />

              {/* <p>Upload a picture</p> */}
              {uploadProgress > 0 && (
                <div className="flex justify-center items-center gap-1 mt-2">
                  <p>{uploadProgress}%</p>

                  <div
                    className={`h-[4px] flex w-full ${
                      uploadProgress === 100 ? "bg-green-700" : "bg-[#ffb7a0]"
                    } rounded-lg`}
                  >
                    <div
                      className={`h-[4px] ${
                        uploadProgress === 100
                          ? "bg-green-700"
                          : "bg-honeConnectOrange"
                      } rounded-lg`}
                      style={{ width: uploadProgress + "%" }}
                    ></div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col text-sm font-medium">
              <label htmlFor="interest" className="mb-1.5">
                Select your preferred interest
              </label>
              <select
                value={registrationData.interest}
                className={inputStyle}
                name="interest"
                onChange={handleInputChange}
              >
                <option value="">Select interest</option>
                <option value="1">Partner</option>
                <option value="2">Relationship</option>
                <option value="3">Dating</option>
                <option value="4">Love</option>
                <option value="5">Friend</option>
                <option value="6">Convo</option>
                <option value="7">Mingle</option>
              </select>
              <span className="text-red-600 text-[10px]">
                {errors.interest}
              </span>
            </div>

            <p className="text-red-600 text-[12px] text-center mb-1">
              {serverError}
            </p>

            <button
              type="submit"
              className="disabled:bg-gray-600 bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-base mt-2"
              disabled={loading}
            >
              {loading ? "Loading..." : "Confirm"}
            </button>
          </form>
        </div>
      </section>

      <Preloader loading={pageLoading} />
    </AuthLayout>
  );
};

export default RegisterStepThree;
