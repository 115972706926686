// login page;
import { Link, Navigate, useNavigate } from "react-router-dom";
import AuthLayout from "../../app-layouts/AuthLayout";
// import { LuChevronLeft } from "react-icons/lu";
import { GoogleIcon } from "../../assets/custom-icons";
import { inputStyle } from "../../app-util/helpers";
import { Checkbox } from "../../app-components/CheckboxComponent";
import { FormEvent, useState } from "react";
import { loginSchema, loginType } from "../../app-schemas/auth.schema";
import apiClient from "../../api-services/ApiClient";
import { SITELINKS } from "../../app-routes/links";
import { GOOGLE_URL, storeToken, storeUser } from "../../api-services/process";
import PasswordToggle from "../../app-components/PasswordToggle";
import { successAlert } from "../../app-util/SweetAlert";
import Preloader from "../../app-components/preloader/Preloader";
import useAuth from "../../app-routes/useAuth";
import { HoneConnectLogo } from "../../assets/img";

const Login = () => {
  const { isAuthenticated, loading: preloading } = useAuth();
  const navigate = useNavigate();

  // component-state
  const [serverError, setServerError] = useState<string>("");
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState<loginType>({
    userID: "",
    password: "",
    isRemember: false,
  });

  // use-state-form-errors
  const [errors, setErrors] = useState<
    Partial<Record<keyof loginType, string>>
  >({});

  // server-error-display

  // handle-input-change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle-checkbox-change
  const handleCheckBox = (name: string, status?: boolean) => {
    setLoginData((prevState) => ({
      ...prevState,
      [name]: status,
    }));
  };

  // handle-login-submit
  const handleEmailLogin = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});
    setServerError("");

    const result = loginSchema.safeParse(loginData);
    if (result.success) {
      try {
        setLoading(true);
        const res = await apiClient.post("login", result.data);
        const user = res.data?.data?.user;

        successAlert();
        storeToken(res.data?.data?.token);
        storeUser(user);
        if (!user?.username || !user?.DOB || !user?.country_id) {
          navigate(SITELINKS.REGISTER_STEP_TWO);
        } else {
          navigate(SITELINKS.HOME);
        }
      } catch (error: any) {
        setServerError(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    } else {
      const formattedErrors: Partial<Record<keyof loginType, string>> = {};

      result.error.errors.forEach((err) => {
        if (err.path.length > 0) {
          const key = err.path[0] as keyof loginType;
          formattedErrors[key] = err.message;
        }
      });

      setErrors(formattedErrors);
    }
  };

  const handleGoogleLogin = () => {
    setPageLoading(true);
    window.location.href = GOOGLE_URL;
  };

  // Show preloader while loading
  if (preloading) {
    return (
      <div>
        <Preloader loading={preloading} />
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to={SITELINKS.HOME} replace />;
  }

  return (
    <AuthLayout>
      <section className="py-3 px-0 lg:col-span-3">
        {/* return to website link */}
        <div className="text-sm font-semibold">
          <Link to="/" className="text-honeConnectPurple flex">
            {/* <LuChevronLeft className="text-xl" /> <span>Back</span> */}

            <img src={HoneConnectLogo} alt="tiny top" className="h-[35px]" />
          </Link>
        </div>

        {/* form section */}
        <div className="pt-10 lg:pt-20 pb-0 px-2 lg:px-10 lg:px-28 text-honeConnectGray">
          {/* hero-text area */}
          <div>
            <h4 className="text-2xl text-honeConnectBlack font-bold mb-2.5">
              Hello!
            </h4>
            <p className="text-sm mb-8 font-medium">
              Welcome to Hone Connect, <br /> Like Minds + Private Connections.
            </p>
          </div>

          {/* react-login-form */}
          <form
            onSubmit={handleEmailLogin}
            autoComplete="off"
            autoCorrect="off"
          >
            <div className="flex flex-col text-sm font-medium mb-4">
              <label htmlFor="userID" className="mb-1">
                Email / Username
              </label>
              <input
                onChange={handleInputChange}
                className={`${inputStyle}`}
                placeholder="Enter your email or username"
                name="userID"
                autoComplete="off"
              />
              <span className="text-red-600 text-[10px]">{errors.userID}</span>
            </div>

            <div className="flex flex-col text-sm text-gray-400 font-medium mb-4 relative">
              <label htmlFor="Password" className="mb-1">
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password_input"
                onChange={handleInputChange}
                className={`${inputStyle}`}
                placeholder="Enter your password"
                autoComplete="off"
              />

              <div className="absolute top-[2.1rem] right-3">
                <PasswordToggle targetId="password_input" />
              </div>
              <span className="text-red-600 text-[10px]">
                {errors.password}
              </span>
            </div>

            <div className="flex items-center justify-between text-xs text-honeConnectGray font-medium mt-6 mb-8">
              <div className="flex items-center gap-1">
                <Checkbox
                  label="Remember Me"
                  checked={loginData.isRemember}
                  onChange={() => {
                    handleCheckBox("isRemember", !loginData.isRemember);
                  }}
                />
              </div>

              <Link to={SITELINKS.FORGOT_PASSWORD}>Forgot Password?</Link>
            </div>

            <p className="text-red-600 text-[12px] text-center mb-4">
              {serverError}
            </p>

            <button
              type="submit"
              className="disabled:bg-gray-600 bg-honeConnectPurple text-white text-sm w-full p-3 rounded-lg border-0"
              disabled={loading}
            >
              Sign In
            </button>
          </form>

          <p className="text-center my-5 mx-0 text-xs text-gray-600">Or</p>

          <button
            type="button"
            onClick={handleGoogleLogin}
            className="flex items-center bg-transparent text-honeConnectGray w-full py-3 px-3 rounded-xl border border-solid border-honeConnectGray text-sm"
          >
            <img
              src={GoogleIcon}
              alt="Google Icon"
              className="w-[25px] h-[25px]"
            />
            <div className="w-3/4 font-[500] font-medium">
              <span>Sign In With Google</span>
            </div>
          </button>

          <div className="text-center mt-8 text-sm">
            Don't have an account?{" "}
            <Link
              to={SITELINKS.REGISTER}
              className="text-honeConnectPurple font-semibold"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </section>

      <Preloader loading={pageLoading} />
    </AuthLayout>
  );
};

export default Login;
