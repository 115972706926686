import { HoneConnectLogoII } from "../../assets/img";
import {
  FaFacebookF,
  FaInstagram,
  FaXTwitter,
  FaHouse,
  FaPlus,
  FaHandshake,
  FaComments,
  FaPeopleArrows,
} from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <footer className="bg-honeConnectPurple text-white py-6 hidden sm:block">
        <div className="container mx-auto grid grid-cols-12 items-center md:justify-items-center">
          <div className="sm:order-2 xl:order-1 sm:col-span-7 xl:col-span-3 flex flex-col gap-2">
            <img src={HoneConnectLogoII} alt="Footer Logo" className="w-28" />
            <p className="font-medium text-sm">
              Hone Connect Copyright @ 2024. <br />
              All rights reserved.
            </p>
          </div>

          <div className="sm:order-1 xl:order-2 sm:col-span-12 xl:col-span-7 sm:mb-10">
            <ul className="flex gap-16 text-sm font-medium">
              <li>
                <Link to="/our-benefit">Our Benefit</Link>
              </li>
              <li>
                <Link to="/how-it-works">How It Works</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>

          <div className="sm:order-3 xl:order-3 sm:col-span-5 xl:col-span-2 flex flex-col gap-3 text-sm font-medium items-center">
            <p className="text-lg">Follow US</p>
            <div className="flex gap-8 text-2xl">
              <a
                href="https://x.com/honeconnect"
                rel="noreferrer"
                target="_blank"
              >
                <FaXTwitter />
              </a>
              <a
                href="https://instagram.com/honeconnect"
                rel="noreferrer"
                target="_blank"
              >
                <FaInstagram />
              </a>
              <a
                href="https://facebook.com/honeconnect"
                rel="noreferrer"
                target="_blank"
              >
                <FaFacebookF />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <footer className="rounded-t-[15px] z-[2] sm:hidden border-t dark:border-slate-500 mobile text-gray-600 dark:text-slate-400 text-[10px] bg-white dark:bg-slate-800 py-5 font-normal fixed bottom-0 w-full">
        <div className="flex justify-between px-7">
          <Link
            to={SITELINKS.HOME}
            className={`${
              currentPath === SITELINKS.HOME && "link-active"
            } flex flex-col gap-[4px] justify-center items-center text-center`}
          >
            <FaHouse className="text-2xl" />
            <p>Home</p>
          </Link>

          <Link
            to={SITELINKS.MY_REQUESTS}
            className={`${
              currentPath === SITELINKS.MY_REQUESTS && "link-active"
            } flex flex-col gap-[4px] justify-center items-center text-center`}
          >
            <FaPeopleArrows className="text-2xl" />
            <p>Requests</p>
          </Link>

          <Link
            to={SITELINKS.MAKE_REQUEST}
            className="shadow-3xl border-honeConnectPurple rounded-link"
          >
            <FaPlus className="text-2xl" />
          </Link>

          <div></div>

          <Link
            to={SITELINKS.MY_RESPONSES}
            className={`${
              currentPath === SITELINKS.MY_RESPONSES && "link-active"
            } flex flex-col gap-[4px] justify-center items-center text-center`}
          >
            <FaHandshake className="text-2xl" />
            <p>Connects</p>
          </Link>

          <Link
            to={SITELINKS.CHAT}
            className={`${
              currentPath === SITELINKS.CHAT && "link-active"
            } flex flex-col gap-[4px] justify-center items-center text-center`}
          >
            <FaComments className="text-2xl" />
            <p>Chats</p>
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
