import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "../../ApiClient";

const sendMessageAPI = async ({
  receiver_id,
  message,
}: {
  receiver_id: number;
  message: any;
}) => {
  const res = await apiClient.post(`chat/send-message`, {
    receiver_id,
    ...message,
  });
  return res.data;
};

export const useSendMessage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendMessageAPI,

    onMutate: async ({ receiver_id, message }) => {
      // Cancel any outgoing refetches for this query
      await queryClient.cancelQueries({ queryKey: ["conversation", receiver_id] });

      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData([
        "conversation",
        receiver_id,
      ]);

      // Optimistically update the query data
      queryClient.setQueryData(["conversation", receiver_id], (old: any) => [
        ...(old || []),
        { ...message, id: Date.now() }, // Add a temporary ID for the new message
      ]);

      // Return a context with the previous value
      return { previousMessages };
    },
    onError: (error, _, context: any) => {
      console.log(error);
      // Rollback to the previous data on error
      if (context?.previousMessages) {
        queryClient.setQueryData(
          ["conversation", _.receiver_id],
          context.previousMessages
        );
      }
    },
  });
};
