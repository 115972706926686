import { LayoutProps } from "./common/layout.types";
import GlobalLayout from "./GlobalLayout";
import Footer from "./~includes/Footer";
import Header from "./~includes/Header";
import MobileNav from "./~includes/MobileNav";
import TopNav from "./~includes/TopNav";

const AppLayout = ({ children, title = "" }: LayoutProps) => (
  <GlobalLayout title={title}>
    <Header />

    <section className="hidden sm:block">
      <TopNav />
    </section>

    <section className="sm:hidden">
      <MobileNav />
    </section>

    <main className="lg:py-5 sm:py-10 xl:py-5 bg-hc_F4EBFA dark:bg-slate-500">
      <section className="sm:container sm:mx-auto">{children}</section>
    </main>

    <Footer />
  </GlobalLayout>
);

export default AppLayout;
