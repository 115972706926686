import AdsBanner from "../../app-layouts/AdsBanner";
import AppLayout from "../../app-layouts/AppLayout";
import HomePageSideNav from "../../app-components/HomeSideNav";
import { Link } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useEffect, useMemo } from "react";
import { APP_STORAGE } from "../../api-services/process";
import Preloader from "../../app-components/preloader/Preloader";
import { useState } from "react";
import { LuUsers2 } from "react-icons/lu";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { GOLD, Loading, SILVER } from "../../assets/img";
import { TERipple } from "tw-elements-react";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { useInterest } from "../../api-services/api-query/Interest/useInterests";
import { getUsersResponses } from "../../api-services/api-query/connection-requests/useUserResponses";

const MyResponses = () => {
  const { ref, inView } = useInView();
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [showModal, setShowModal] = useState(false);
  const interestData = useInterest();
  const [interestId, setInterestId] = useState<number>(1);

  const filterInterest = (data: any) => {
    if (data) {
      const uniqueInterests = new Map();

      data.forEach((item: any) => {
        const interest = item.interest;
        uniqueInterests.set(interest.id, interest);
      });
      const interestsArray = Array.from(uniqueInterests.values());

      return interestsArray;
    }

    return [];
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ["my_responses"],
      queryFn: getUsersResponses,
      initialPageParam: 1,
      getNextPageParam: (data) =>
        data.currentPage < data.totalPages ? data.currentPage + 1 : undefined,
    });

  const getImageSrc = (post: any) => {
    if (post?.connection_request?.caption_image)
      return `${APP_STORAGE}gallery_pictures/${post?.connection_request?.caption_image}`;
    else if (post?.connection_request?.user?.profile_pix_link)
      return `${APP_STORAGE}${post?.connection_request?.user?.profile_pix_link}`;
    return "img/PrivateAccount.png";
  };

  const getBadgeSrc = (badge: string) => {
    switch (badge) {
      case "GOLD":
        return GOLD;
      case "SILVER":
        return SILVER;
      default:
        return "";
    }
  };

  const connectionComponent = useMemo(() => {
    if (!data || data.pages.length === 0 || data.pages[0].data.length === 0) {
      return (
        <div className="text-center text-gray-500 dark:text-gray-200 dark:bg-slate-700 mt-[25vh]">
          <p className="mb-5 text-sm">No history available</p>
          <Link
            className="bg-honeConnectPurple dark:bg-slate-800 dark:text-gray-300 py-2 px-5 border text-white shadow rounded-lg"
            to={SITELINKS.MAKE_REQUEST}
          >
            Make a request
          </Link>
        </div>
      );
    }

    return data.pages.flatMap((page) =>
      page.data.map((post: any, index: number) => (
        <Link to={`${SITELINKS.MY_PROFILE}/${post?.connection_request?.user?.username}`}
          key={post.uuid}
          ref={index === 5 ? ref : undefined}
          className="flex gap-5 bg-white dark:bg-slate-800 lg:rounded-10px sm:mb-0 p-2 py-3 mx-2 rounded-10px p-3.5"
        >
          <div className="overflow-hidden w-[80px] max-h-[80px] overflow-hidden rounded-xl">
            <img
              src={getImageSrc(post)}
              alt="Display"
              className="w-full rounded-xl bg-gray-100 dark:border-slate-700 border"
            />
          </div>

          <div className="justify-between gap-2">
            {/* connect interest */}
            <div className="flex gap-2 w-full relative">
              <p className="text-lg flex gap-2 font-medium dark:text-gray-300">
                {post?.connection_request?.user?.username}
              </p>

              {post?.connection_request?.user?.badge && (
                <img
                  src={getBadgeSrc(post?.connection_request?.user?.badge)}
                  alt={`${post?.connection_request?.user?.badge} Badge`}
                  className="w-8 top-[-4px] relative"
                />
              )}
            </div>

            <div className="flex gap-2 w-full dark:text-gray-300">
              <p className="text-xs flex gap-2 font-normal">
                {post?.connection_request?.interest?.interest}
              </p>
            </div>

            <div className="flex gap-2 w-full dark:text-gray-400">
              <p className="text-xs flex gap-2 font-normal">
                {post?.connection_request?.prespecified_text?.content}
              </p>
            </div>

            <div className="flex gap-2 w-full dark:text-gray-400">
              <p className="text-xs flex gap-2 font-normal items-center">
                {/* <FaMapMarkerAlt /> */}
                {post?.connection_request?.city?.name},{" "}
                {post?.connection_request?.state?.name},{" "}
                {post?.connection_request?.country?.name}
              </p>
            </div>
          </div>
        </Link>
      ))
    );
    // eslint-disable-next-line
  }, [data, ref]);

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? "up" : "down";
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  if (isLoading) {
    return (
      <AppLayout title="Home">
        <Preloader loading={true} />
      </AppLayout>
    );
  }

  return (
    <AppLayout title="My Connect">
      <div className="grid grid-cols-12 gap-8 justify-between dark:bg-slate-700 h-[100vh]">
        <HomePageSideNav />

        <div className="flex bg-hc_F4EBFA dark:bg-slate-700 border-b border-gray-100 dark:border-slate-800 col-span-12 sm:col-span-7 w-full">
          <div className="flex flex-col w-full">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-28 mb-28 w-full">
              <div
                className={`shadow flex justify-between w-full bg-white dark:bg-slate-800 border-b dark:border-slate-600 z-10 fixed transition-all duration-500 top-0 ${
                  scrollDirection === null
                    ? "translate-y-[57px]"
                    : scrollDirection === "up"
                    ? "translate-y-0 top-[57px]"
                    : "-translate-y-full"
                }`}
              >
                <TERipple rippleColor="#aaa">
                  <button className="py-3 lg:relative lg:rounded-10px font-medium lg:text-lg lg:p-3 px-4 flex gap-3 items-center">
                    <LuUsers2
                      style={{ fill: "#d4b1e6" }}
                      className="fa-solid fa-users text-gray-600 dark:text-gray-300 text-2xl"
                    />
                    <p className="text-honeConnectBlack dark:text-gray-300">
                      {`${data?.pages[0]?.totalItems ?? "0"} Connect${
                        data?.pages[0]?.totalItems > 1 ? "s" : ""
                      }`}
                    </p>
                  </button>
                </TERipple>

                <TERipple rippleColor="#aaa">
                  <button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="hidden py-3 lg:text-lg lg:p-3 px-4 flex gap-2 items-center pr-3 font-medium"
                  >
                    <TbAdjustmentsHorizontal
                      style={{ fill: "#d4b1e6" }}
                      className="text-2xl text-gray-600 dark:text-gray-300"
                    />
                    <span className="text-honeConnectBlack hidden">
                      All filters
                    </span>
                  </button>
                </TERipple>
              </div>

              {connectionComponent}

              {isFetchingNextPage && (
                <div className="flex py-5 pb-16 justify-center">
                  <img
                    src={Loading}
                    alt="loader"
                    className="h-[20px] w-[20px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <AdsBanner />
      </div>

      {/* <!-- Modal --> */}
      <TEModal show={showModal}>
        <TEModalDialog className="rounded-0">
          <TEModalContent className="h-[100vh]  dark:bg-slate-800 overflow-scroll rounded-t-none">
            <TEModalHeader className="bg-honeConnectPurple dark:bg-slate-800 text-white rounded-t-none px-5">
              {/* <!--Modal title--> */}
              <h5 className="text-md font-normal leading-normal text-white">
                Filter Results
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            {/* <!--Modal body--> */}
            <TEModalBody>
              <div className="px-3 py-5">
                <p className="text-honeConnectBlack dark:text-gray-300 text-xs font-medium mb-3">
                  Filter by Interest
                </p>

                <div className="grid grid-cols-2 xl:grid-cols-2 gap-y-5 items-center mt-2 mb-3">
                  {filterInterest(interestData.data).map((item: any) => {
                    return (
                      <label
                        key={item.id}
                        className="items-center xl:col-span-1 custom-checkbox"
                      >
                        <input
                          type="checkbox"
                          name=""
                          id="col1"
                          className="h-5 w-5 accent-purple"
                          onChange={(e) => {
                            setInterestId(item.id);
                          }}
                          checked={item.id === interestId}
                        />
                        <span className="checkmark"></span>
                        <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                          {item.interest}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>

              {/* filter by gender */}
              <div className="px-3 border-t py-5">
                <p className="text-honeConnectBlack dark:text-gray-300 text-xs font-medium mb-3">
                  Filter by Gender
                </p>

                <div className="flex gap-7">
                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="col1"
                      className="h-5 w-5 accent-purple"
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                      Male
                    </span>
                  </label>

                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="col1"
                      className="h-5 w-5 accent-purple"
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                      Female
                    </span>
                  </label>
                </div>
              </div>

              {/* filter by date */}
              <div className="px-3 py-5 border-t">
                <p className="text-honeConnectBlack text-xs font-semibold mb-3">
                  Filter by Date
                </p>

                <div className="grid grid-cols-2 xl:grid-cols-2 gap-y-5">
                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        All time
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        Last 7 days
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        Last 3 days
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        Last 24 hours
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        Today
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {/* filter by location */}
              <div className="px-6 mb-24 border-t pt-5 hidden">
                <p className="text-honeConnectBlack text-base font-semibold mb-3">
                  Filter by Location
                </p>
                <select
                  name=""
                  id=""
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                >
                  <option value="">Select Location</option>
                  <option value="">Option 1</option>
                  <option value="">Option 2</option>
                </select>
              </div>
            </TEModalBody>

            <TEModalFooter className="flex gap-3">
              <TERipple rippleColor="light" className="">
                <button
                  type="button"
                  className="w-full text-honeConnectPurple dark:text-gray-300 inline-block rounded bg-primary-100 px-6 py-3 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 dark:bg-slate-800  dark:border-slate-400 border"
                  onClick={() => setShowModal(false)}
                >
                  Reset
                </button>
              </TERipple>
              <TERipple rippleColor="light" className="w-2/3">
                <button
                  type="button"
                  className="w-full inline-block rounded px-6 py-3 text-sm font-medium uppercase text-gray-500 bg-honeConnectPurple text-white shadow  dark:text-gray-300 border dark:border-slate-600 dark:bg-slate-700"
                >
                  Show
                </button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </AppLayout>
  );
};

export default MyResponses;
