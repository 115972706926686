import AdsBanner from "../../app-layouts/AdsBanner";
import AppLayout from "../../app-layouts/AppLayout";
import HomePageSideNav from "../../app-components/HomeSideNav";
import { Link, useParams } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useEffect, useMemo } from "react";
import { APP_STORAGE } from "../../api-services/process";
import Preloader from "../../app-components/preloader/Preloader";
import { useState } from "react";
import { LuArrowLeft, LuUsers2 } from "react-icons/lu";
import { GOLD, Loading, SILVER } from "../../assets/img";
import { TERipple } from "tw-elements-react";
import { getUsersRequestsMembers } from "../../api-services/api-query/connection-requests/useUserRequestsMembers";
import { formatTimestampToRelativeTime } from "../../app-util";

const MyRequestMembers = () => {
  const { id } = useParams();
  const { ref, inView } = useInView();
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ["my_responses_users", id],
      queryFn: async ({ pageParam = 1 }) =>
        getUsersRequestsMembers({ id, page: pageParam }),
      initialPageParam: 1,
      getNextPageParam: (lastPage) =>
        lastPage.currentPage < lastPage.totalPages
          ? lastPage.currentPage + 1
          : undefined,
    });

  const getImageSrc = (post: any) => {
    if (post?.user?.profile_picture)
      return `${APP_STORAGE}${post?.user?.profile_picture}`;
    return "img/PrivateAccount.png";
  };

  const getBadgeSrc = (badge: string) => {
    switch (badge) {
      case "GOLD":
        return GOLD;
      case "SILVER":
        return SILVER;
      default:
        return "";
    }
  };

  const connectionComponent = useMemo(() => {
    if (!data || data.pages.length === 0 || data.pages[0].data.length === 0) {
      return (
        <div className="text-center text-gray-500 dark:text-gray-200 dark:bg-slate-700 h-[100vh] mt-[25vh]">
          <p className="mb-5 text-sm">No members available</p>
          <Link
            className="bg-honeConnectPurple dark:bg-slate-800 dark:text-gray-300 py-2 px-5 border text-white shadow rounded-lg"
            to={SITELINKS.MY_REQUESTS}
          >
            Go to history
          </Link>
        </div>
      );
    }

    return data.pages.flatMap((page) =>
      page.data.map((post: any, index: number) => (
        <Link
          to={`${SITELINKS.MY_PROFILE}/${post?.user?.username}`}
          key={index}
          ref={index === 5 ? ref : undefined}
          className="flex gap-5 bg-white dark:bg-slate-800 lg:rounded-10px sm:mb-0 p-2 py-3 mx-2 rounded-10px p-3.5"
        >
          <div className="overflow-hidden w-[80px] max-h-[60px] overflow-hidden rounded-xl">
            <img
              src={getImageSrc(post)}
              alt="Display"
              className="w-full rounded-xl bg-gray-100 dark:border-slate-700 border"
            />
          </div>

          <div className="flex justify-between w-full items-center">
            <div>
              {/* connect interest */}
              <div className="flex gap-1 w-full relative">
                <p className="text-lg flex gap-2 font-medium dark:text-gray-300">
                  {post?.user?.username}
                </p>

                {post?.user?.badge && (
                  <img
                    src={getBadgeSrc(post?.user?.badge)}
                    alt={`${post?.user?.badge} Badge`}
                    className="w-8 top-[-4px] relative"
                  />
                )}
              </div>

              <div className="w-full dark:text-gray-300">
                <span className="px-2 dark:bg-slate-700 text-sm font-normal bg-gray-100 rounded-full">{post?.interest}</span>
              </div>
            </div>

            <div className="text-end">
              <span className="text-xs text-gray-500 dark:text-gray-400">
                {formatTimestampToRelativeTime(post?.created_at)}
              </span>
            </div>
          </div>
        </Link>
      ))
    );
    // eslint-disable-next-line
  }, [data, ref]);

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? "up" : "down";
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  if (isLoading) {
    return (
      <AppLayout title="My Request Members">
        <Preloader loading={true} />
      </AppLayout>
    );
  }

  return (
    <AppLayout title="My Request Members">
      <div className="grid grid-cols-12 gap-8 justify-between dark:bg-slate-700 h-[100vh]">
        <HomePageSideNav />

        <div className="flex bg-hc_F4EBFA dark:bg-slate-700 border-b border-gray-100 dark:border-slate-800 col-span-12 sm:col-span-7 w-full">
          <div className="flex flex-col w-full">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-28 mb-28 w-full">
              <div
                className={`shadow flex justify-between w-full bg-white dark:bg-slate-800 border-b dark:border-slate-600 z-10 fixed transition-all duration-500 top-0 ${
                  scrollDirection === null
                    ? "translate-y-[57px]"
                    : scrollDirection === "up"
                    ? "translate-y-0 top-[57px]"
                    : "-translate-y-full"
                }`}
              >
                <TERipple rippleColor="#aaa" className="flex items0center">
                  <Link className="py-3 px-3" to={SITELINKS.MY_REQUESTS}>
                    <LuArrowLeft className="text-2xl font-medium fa-solid fa-users text-gray-600 dark:text-gray-300 text-2xl" />
                  </Link>

                  <button className="py-3 lg:relative lg:rounded-10px font-medium lg:text-lg lg:p-3 px-2 flex gap-3 items-center">
                    <LuUsers2
                      style={{ fill: "#d4b1e6" }}
                      className="text-gray-600 dark:text-gray-300 text-2xl"
                    />
                    <p className="text-honeConnectBlack dark:text-gray-300">
                      {`${data?.pages[0]?.totalItems ?? "0"} Linked User${
                        data?.pages[0]?.totalItems > 1 ? "s" : ""
                      }`}
                    </p>
                  </button>
                </TERipple>
              </div>

              {connectionComponent}

              {isFetchingNextPage && (
                <div className="flex py-5 pb-16 justify-center">
                  <img
                    src={Loading}
                    alt="loader"
                    className="h-[20px] w-[20px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <AdsBanner />
      </div>
    </AppLayout>
  );
};

export default MyRequestMembers;
