import React, { useState } from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import { LuCheck, LuChevronLeft } from "react-icons/lu";
import { Link, useParams } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import PaymentButton from "../../app-util/PayWithPaystack";
import { FaCaretDown } from "react-icons/fa";
import { GOLD, SILVER } from "../../assets/img";

const Subscriptions = () => {
  const { id } = useParams() || {};

  console.log(id);

  const [plan, setPlan] = useState(1);
  const availPlans = [
    {
      key: 1,
      value: "Month",
      discount: 0,
    },
    {
      key: 3,
      value: "Quarter",
      discount: 2.5 / 100,
    },
    {
      key: 6,
      value: "Bi-Annual",
      discount: 5 / 100,
    },
    {
      key: 12,
      value: "Year",
      discount: 7.5 / 100,
    },
  ];

  const calculateDiscountedPrice = (
    basePrice: number,
    discount: number | undefined
  ): number => {
    const finalPrice = basePrice * (1 - (discount ?? 0));
    return Math.round(finalPrice / 10) * 10 * plan;
  };

  return (
    <AppLayout>
      <section className="bg-hc_F4EBFA min-h-[100vh] sm:pt-10 mb-20">
        <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 sm:mx-auto">
          <div className="col-span-12 md:col-span-10 bg-hc_F4EBFA min-h-[100vh] dark:bg-slate-700 rounded-10px py-10 sm:px-7">
            <div className="mb-8 hidden sm:block">
              <Link
                to={SITELINKS.HOME}
                className="text-honeConnectPurple flex items-center font-semibold text-sm"
              >
                <LuChevronLeft />
                <span>Back</span>
              </Link>
            </div>

            <div className="sm:mb-10 mt-8 mb-2 px-4">
              <p className="font-medium text-lg text-honeConnectBlack dark:text-gray-300">
                Make Payment
              </p>
            </div>

            <div className="flex sm:gap-5 gap-1 px-4 justify-center overflow-hidden">
              <div className="text-center flex flex-col items-center h-full w-1/4">
                <button
                  className={`text-sm py-2 sm:px-4 px-2 dark:bg-slate-800 dark:text-gray-300 dark:border-slate-800 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px] h-[80px] w-full ${
                    plan === 1 &&
                    "bg-honeConnectPurple dark:bg-honeConnectPurple text-white"
                  }`}
                  onClick={() => {
                    setPlan(1);
                  }}
                >
                  Monthly
                </button>
                {plan === 1 && (
                  <FaCaretDown className="text-3xl text-honeConnectPurple relative top-[-13px]" />
                )}
              </div>

              <div className="text-center flex flex-col items-center h-full w-1/4">
                <button
                  className={`text-sm py-2 sm:px-4 px-2 dark:bg-slate-800 dark:text-gray-300 dark:border-slate-800 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px] h-[80px] w-full ${
                    plan === 3 &&
                    "bg-honeConnectPurple dark:bg-honeConnectPurple text-white"
                  }`}
                  onClick={() => {
                    setPlan(3);
                  }}
                >
                  Quarterly
                </button>
                {plan === 3 && (
                  <FaCaretDown className="text-3xl text-honeConnectPurple relative top-[-13px]" />
                )}
              </div>

              <div className="text-center flex flex-col items-center h-full w-1/4">
                <button
                  className={`text-sm py-2 sm:px-4 px-2 dark:bg-slate-800 dark:text-gray-300 dark:border-slate-800 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px] h-[80px] w-full ${
                    plan === 6 &&
                    "bg-honeConnectPurple dark:bg-honeConnectPurple  text-white"
                  }`}
                  onClick={() => {
                    setPlan(6);
                  }}
                >
                  Bi-Annually
                </button>
                {plan === 6 && (
                  <FaCaretDown className="text-3xl text-honeConnectPurple relative top-[-13px]" />
                )}
              </div>

              <div className="text-center flex flex-col items-center h-full w-1/4">
                <button
                  className={`text-sm py-2 sm:px-4 px-2 dark:bg-slate-800 dark:text-gray-300 border dark:border-slate-800 border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px] h-[80px] w-full ${
                    plan === 12 &&
                    "bg-honeConnectPurple dark:bg-honeConnectPurple  text-white"
                  }`}
                  onClick={() => {
                    setPlan(12);
                  }}
                >
                  Annually
                </button>
                {plan === 12 && (
                  <FaCaretDown className="text-3xl text-honeConnectPurple relative top-[-13px]" />
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-6 sm:gap-10 md:gap-5 px-4 sm:pt-5">
              <div className="border border-solid border-honeConnectGray200 dark:border-slate-600 hover:border-honeConnectPurple bg-honeConnectGray100 rounded-10px dark:bg-slate-800">
                <div className="sm:p-2 p-2">
                  <div className="flex gap-1 justify-start sm:justify-center items-center mt:2 sm:mt-14">
                    <p className="font-medium text-honeConnectBlack dark:text-gray-300 text-[25px] text-left sm:text-center">
                      Standard
                    </p>
                  </div>

                  <p className="font-normal sm:text-[50px] text-[30px] text-honeConnectBlack dark:text-gray-300 sm:mt-8 text-left sm:text-center">
                    ₦100
                  </p>

                  <p className="font-normal text-sm text-honeConnectGray mt-1 text-left sm:text-center dark:text-gray-400">
                    One Off Payment
                  </p>

                  <PaymentButton
                    amount={id ? 100 : 0}
                    plan="STANDARD"
                    duration={0}
                    btnLabel={"Pay per connect"}
                  />

                  <div className="flex flex-col gap-4 mb-1 sm:mb-12">
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">Enjoy free unlimited requests.</p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">
                        5 Phone Exclusion (Privacy) Benefits.
                      </p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">
                        5 Email Exclusion (Privacy) Benefits
                      </p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs"> Pay one-off fee per connect</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border border-solid border-honeConnectGray200 hover:border-honeConnectPurple bg-honeConnectGray100 rounded-10px dark:bg-slate-800">
                <div className="bg-hc_F3A838 rounded-t-10px text-center py-2 text-white font-semibold text-sm">
                  Most Popular
                </div>
                <div className="sm:p-2 p-2">
                  <div className="flex gap-1 justify-start sm:justify-center items-center">
                    <p className="font-medium text-honeConnectBlack text-[25px]  dark:text-gray-300">
                      Silver
                    </p>
                    <img src={SILVER} className="h-9 hidden" alt="" />
                  </div>

                  <p className="font-normal sm:text-[50px] text-[30px] text-honeConnectBlack dark:text-gray-300 sm:mt-8 text-left sm:text-center">
                    {plan !== 1 && (
                      <small className="block text-[18px] line-through">
                        ₦{500 * plan}
                      </small>
                    )}
                    <span className="block">
                      ₦
                      {calculateDiscountedPrice(
                        500,
                        availPlans?.find((item: any) => item.key === plan)
                          ?.discount
                      )}
                    </span>
                  </p>

                  <p className="font-normal text-sm text-honeConnectGray mt-1 text-left sm:text-center dark:text-gray-400">
                    Per{" "}
                    {availPlans?.find((item: any) => item.key === plan)?.value}
                  </p>

                  <PaymentButton
                    amount={calculateDiscountedPrice(
                      500,
                      availPlans?.find((item: any) => item.key === plan)
                        ?.discount
                    )}
                    plan="SILVER"
                    duration={plan}
                    btnLabel={"Subscribe"}
                  />

                  <div className="flex flex-col gap-4 mb-1 sm:mb-12">
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">Enjoy free unlimited requests.</p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">
                        50 Phone Exclusion (Privacy) Benefits.
                      </p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">
                        50 Email Exclusion (Privacy) Benefits.
                      </p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">50 exclusion benefit</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border border-solid border-honeConnectGray200 bg-honeConnectGray100 rounded-10px hover:border-honeConnectPurple  dark:bg-slate-800">
                <div className="sm:p-2 p-2">
                  <div className="flex gap-1 justify-start sm:justify-center items-center mt-1 sm:mt-11">
                    <p className="font-medium text-honeConnectBlack text-[25px] dark:text-gray-300">
                      Gold
                    </p>
                    <img src={GOLD} className="h-9 hidden" alt="" />
                  </div>

                  <p className="font-normal sm:text-[50px] text-[30px] text-honeConnectBlack dark:text-gray-300 mt-1 sm:mt-8 text-left sm:text-center">
                    {plan !== 1 && (
                      <small className="block text-[18px] line-through">
                        ₦{1500 * plan}
                      </small>
                    )}
                    <span className="block">
                      ₦
                      {calculateDiscountedPrice(
                        1500,
                        availPlans?.find((item: any) => item.key === plan)
                          ?.discount
                      )}
                    </span>
                  </p>
                  <p className="font-normal text-sm text-honeConnectGray  dark:text-gray-400 mt-1 text-left sm:text-center">
                    Per{" "}
                    {availPlans?.find((item: any) => item.key === plan)?.value}
                  </p>

                  <PaymentButton
                    amount={calculateDiscountedPrice(
                      1500,
                      availPlans?.find((item: any) => item.key === plan)
                        ?.discount
                    )}
                    plan="GOLD"
                    duration={plan}
                    btnLabel={"Subscribe"}
                  />

                  <div className="flex flex-col gap-4 mb-1 sm:mb-12">
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">Enjoy free unlimited requests.</p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">Enjoy Priority requests.</p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">
                        300 Phone Exclusion (Privacy) Benefits.
                      </p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">
                        300 Email Exclusion (Privacy) Benefits.
                      </p>
                    </div>
                    <div className="flex gap-2 font-normal text-honeConnectGray dark:text-gray-400">
                      <div>
                        <LuCheck className="text-xl" />
                      </div>
                      <p className="text-xs">Enjoy Unlimited connections.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AdsBanner></AdsBanner>
        </div>
      </section>
    </AppLayout>
  );
};

export default Subscriptions;
