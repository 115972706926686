// registration component
import React, { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../app-layouts/AuthLayout";
// import { LuChevronLeft } from "react-icons/lu";
import {
  RegisterStepOneSchema,
  RegisterStepOneType,
} from "../../app-schemas/auth.schema";
import apiClient from "../../api-services/ApiClient";
import { inputStyle } from "../../app-util/helpers";
import { Checkbox } from "../../app-components/CheckboxComponent";
import { successAlert } from "../../app-util/SweetAlert";
import { SITELINKS } from "../../app-routes/links";
import { HoneConnectLogo } from "../../assets/img";
import PasswordToggle from "../../app-components/PasswordToggle";

const RegisterStepOne = () => {
  // component-state
  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(false);
  const [registrationData, setRegistrationData] = useState<
    Partial<RegisterStepOneType>
  >({});

  // use-state-form-errors
  const [errors, setErrors] = useState<
    Partial<Record<keyof RegisterStepOneType, string>>
  >({});

  // server-error-display
  const [serverError, setServerError] = useState<string>("");

  // handle-input-change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRegistrationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle-checkbox-change
  const handleCheckBox = (name: string, status?: boolean) => {
    setRegistrationData((prevState) => ({
      ...prevState,
      [name]: status,
    }));
  };

  // handle-login-submit
  const handleEmailSignUp = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});
    setServerError("");

    const result = RegisterStepOneSchema.safeParse(registrationData);
    if (result.success) {
      try {
        setPageLoading(true);
        const data = { ...result.data, full_name: result.data.fullname };
        await apiClient.post("register-step-one", data);

        successAlert();
        setRegistrationData({});
        navigate(SITELINKS.LOGIN);
      } catch (error: any) {
        console.log(error);
        setServerError(JSON.stringify(error));
      } finally {
        setPageLoading(false);
      }
    } else {
      const formattedErrors: Partial<
        Record<keyof RegisterStepOneType, string>
      > = {};

      result.error.errors.forEach((err) => {
        if (err.path.length > 0) {
          const key = err.path[0] as keyof RegisterStepOneType;
          formattedErrors[key] = err.message;
        }
      });

      setErrors(formattedErrors);
      console.log(formattedErrors);
    }
  };

  return (
    <AuthLayout>
      <section className="py-3 px-0 lg:col-span-3">
        {/* return to website link */}
        <div className="text-sm font-semibold flex justify-between">
          <Link to="/" className="text-honeConnectPurple flex">
            {/* <LuChevronLeft className="text-xl mt-[1px]" /> <span>Back</span> */}

            <img src={HoneConnectLogo} alt="tiny top" className="h-[35px]" />
          </Link>

          <p className="uppercase text-honeConnectGray hidden sm:block">
            Step 1/3
          </p>
        </div>

        {/* form section */}
        <div className="pt-10 lg:pt-20 pb-0 px-2 lg:px-10 lg:px-28 text-honeConnectGray">
          <h4 className="text-2xl text-honeConnectBlack font-bold mb-2.5">
            Register An Account
          </h4>
          <p className="text-sm mb-4 font-medium">
            Don't have an account?, it takes less than a minute.
          </p>

          <form
            onSubmit={handleEmailSignUp}
            autoComplete="off"
            autoCorrect="off"
          >
            {/* enter fullname */}
            <div className="flex flex-col text-sm font-medium mb-2">
              <label htmlFor="fullName" className="mb-1.5">
                Full Name
              </label>
              <input
                onChange={handleInputChange}
                type="text"
                className={`${inputStyle}`}
                placeholder="Enter your full name"
                name="fullname"
              />
              <span className="text-red-600 text-[10px]">
                {errors.fullname}
              </span>
            </div>

            {/* enter email */}
            <div className="flex flex-col text-sm font-medium mb-2">
              <label htmlFor="email" className="mb-1.5">
                Email
              </label>
              <input
                onChange={handleInputChange}
                type="text"
                className={`${inputStyle}`}
                placeholder="Enter Email"
                name="email"
              />
              <span className="text-red-600 text-[10px]">{errors.email}</span>
            </div>

            {/* enter password */}
            <div className="flex flex-col text-sm text-gray-400 font-medium mb-2 relative">
              <label htmlFor="cPassword" className="mb-1.5">
                Password
              </label>
              <input
                onChange={handleInputChange}
                type="password"
                className={`${inputStyle}`}
                placeholder="Enter your password"
                name="password"
                id="password"
              />
              <span className="text-red-600 text-[10px]">
                {errors.password}
              </span>

              <span className="absolute right-3 top-9">
                <PasswordToggle targetId="password" />
              </span>
            </div>

            {/* confirm password */}
            <div className="flex flex-col text-sm text-gray-400 font-medium mb-2 relative">
              <label htmlFor="password" className="mb-1.5">
                Confirm Password
              </label>
              <input
                onChange={handleInputChange}
                type="password"
                className={`${inputStyle}`}
                placeholder="Confirm password"
                name="confirm_password"
                id="confirm_password"
              />
              <span className="text-red-600 text-[10px]">
                {errors.confirm_password}
              </span>

              <span className="absolute right-3 top-9">
                <PasswordToggle targetId="confirm_password" />
              </span>
            </div>

            {/* checkbox */}
            <div className="text-sm text-honeConnectGray font-medium mb-5">
              <div className="flex items-center gap-2 text-xs">
                <Checkbox
                  label="I certify that I am at least 18 years
                  old"
                  checked={registrationData.isEligible}
                  onChange={() => {
                    handleCheckBox("isEligible", !registrationData.isEligible);
                  }}
                />
              </div>
              <span className="text-red-600 text-[10px]">
                {errors.isEligible}
              </span>
            </div>

            <p className="text-red-600 text-[12px] text-center mb-2">
              {serverError}
            </p>

            <button
              type="submit"
              className="disabled:bg-gray-600 bg-honeConnectPurple text-white text-sm w-full p-3 rounded-lg border-0 mt-3"
              disabled={pageLoading}
            >
              Continue
            </button>

            <p className="text-center my-5 mx-0 text-sm text-honeConnectGray font-normal">
              By signing up to create an account I agree to the
              <Link
                to={"/terms-and-conditions"}
                className="text-honeConnectOrange"
              >
                Terms & Conditions
              </Link>{" "}
              and
              <Link to={"/privacy-policy"} className="text-honeConnectOrange">
                {" "}
                Privacy Policy.
              </Link>
            </p>
          </form>
        </div>
      </section>
    </AuthLayout>
  );
};

export default RegisterStepOne;
