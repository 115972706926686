import apiClient from "../../ApiClient";

export const getUsersRequestsMembers = async (id: any, pageParam = 1) => {
  console.log(id)
  const res = await apiClient.get("user-connection-requests-members", {
    params: {
      page: pageParam,
      request_id: id.id,
    },
  });

  return {
    data: res.data.data ?? [],
    totalPages: res.data?.last_page ?? 0,
    totalItems: res.data?.total ?? 0,
    currentPage: res.data?.current_page ?? 0,
  };
};
