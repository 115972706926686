import React from "react";
import APPRoutes from "./app-routes";
import { ThemeProvider } from "./app-store/ThemeContext";

function App() {
  return (
    <ThemeProvider>
      <APPRoutes />
    </ThemeProvider>
  );
}

export default App;
