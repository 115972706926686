import React, { useEffect, useState } from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import SuggestionsSideBar from "../../app-components/SuggestionsSideBar";
import { TERipple } from "tw-elements-react";
import { useNotification } from "../../api-services/api-query/notifications/useNotification";
import { APP_STORAGE } from "../../api-services/process";
import apiClient from "../../api-services/ApiClient";
import { useUnreadNotificationCounter } from "../../api-services/api-query/notifications/useUnreadNotificationCounter";

const Notifications = () => {
  const notification = useNotification();
  const unreadNotification = useUnreadNotificationCounter();
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    if (notification.isFetched) {
      const timeout = setTimeout(() => {
        markAllAsRead();
      }, 5000); // Delay for 2 seconds

      // Cleanup the timeout on component unmount or if the effect runs again
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.isFetched]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? "up" : "down";
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const markAllAsRead = async () => {
    try {
      await apiClient.patch("notifications/mark-all-as-read");
      unreadNotification.refetch();
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
  };

  return (
    <AppLayout>
      <main className="sm:py-10">
        <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 mx-auto dark:bg-slate-700 bg-hc_F4EBFA min-h-[100vh]">
          <SuggestionsSideBar />

          <div
            className={`shadow flex justify-between w-full bg-white dark:bg-slate-800 border-b dark:border-slate-600 z-10 fixed transition-all duration-500 top-0 p-3 py-3 ${
              scrollDirection === null
                ? "translate-y-[57px]"
                : scrollDirection === "up"
                ? "translate-y-0 top-[57px]"
                : "-translate-y-full"
            }`}
          >
            <p className="text-lg font-medium text-honeConnectBlack dark:text-gray-300">
              Notifications
            </p>
          </div>

          <div className="mt-24 col-span-12 md:col-span-8 lg:col-span-7 sm:rounded-10px py-6 px-2 pb-28">
            {notification?.data?.map((item: any) => {
              return (
                <TERipple rippleColor="white" className="mb-3 w-full">
                  <div
                    className={`${
                      item?.is_read
                        ? "bg-honeConnectGray50 dark:bg-slate-800"
                        : "bg-[#FFE2B7]"
                    } rounded-10px p-3.5 flex gap-5 w-full`}
                  >
                    <div className="overflow-hidden  w-[130px] max-h-[80px] overflow-hidden rounded-xl">
                      <img
                        src={
                          !!item.responder
                            ? APP_STORAGE + item.responder.profile_pix_link
                            : "img/HoneConnect.png"
                        }
                        className="w-full rounded-xl bg-gray-100 dark:border-slate-700 border"
                        alt="meme"
                      />
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                      <p className="text-sm font-bold text-honeConnectBlack dark:text-gray-300">
                        {item.type}
                      </p>
                      <p className="text-xs font-normal text-honeConnectGray dark:text-gray-400">
                        {item.message}
                      </p>
                      <p className="text-xs font-medium text-honeConnectBlack dark:text-gray-400">
                        {item.created_at}
                      </p>
                    </div>
                  </div>
                </TERipple>
              );
            })}

            {notification?.data?.length < 1 && (
              <p className="text-center w-full mt-20 text-sm">
                No notification
              </p>
            )}
          </div>

          <AdsBanner></AdsBanner>
        </div>
      </main>
    </AppLayout>
  );
};

export default Notifications;
