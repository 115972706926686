// login component
import React, { FormEvent, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import apiClient from "../../api-services/ApiClient";
import { getUser, storeUser } from "../../api-services/process";
import ImageCrop from "../../app-components/image-cropper/ImageCrop";
import AppLayout from "../../app-layouts/AppLayout";
import {
  UpdateUserInfoSchema,
  UpdateUserInfoType,
} from "../../app-schemas/auth.schema";
import { UserProfileInterface } from "../../app-schemas/user.interface";
import { inputStyle } from "../../app-util/helpers";
import { errorAlert, successAlert } from "../../app-util/SweetAlert";
import MyProfileSideBar from "../home/myProfileSideBar";

const EditProfile = () => {
  const user: UserProfileInterface = getUser();

  const [loading, setLoading] = useState<boolean>(false);

  const [serverError, setServerError] = useState<string>("");

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  const [userInfo, setUserInfo] = useState<Partial<UpdateUserInfoType>>({
    full_name: user?.full_name,
    email: user?.email,
    phone_number: user?.phone_number,
    username: user?.username,
    DOB: user.DOB,
    gender: user?.gender,
    bio: user?.bio ?? "",
    interest: user?.interests[0]?.id.toString() ?? "",

    country_id: user?.country_id.toString() || "",
    state_id: user?.state_id.toString() || "",
    city_id: user?.city_id.toString() || "",
    area: user?.area,

    instagram_id: user?.instagram_id ?? "",
    snapchat_id: user?.snapchat_id ?? "",
    twitter_x_id: user?.twitter_x_id ?? "",
  });

  const [errors, setErrors] = useState<
    Partial<Record<keyof UpdateUserInfoType, string>>
  >({});

  useEffect(() => {
    handleCountries();
  }, []);

  useEffect(() => {
    const countryId = userInfo?.country_id;

    if (user?.country_id.toString() !== countryId) {
      setUserInfo((prevState: any) => ({
        ...prevState,
        city_id: 0,
        state_id: 0,
      }));

      setStates([]);
      setCities([]);
    }
    if (countryId) handleStates(Number(countryId) ?? 0);
  }, [userInfo?.country_id, user?.country_id]);

  useEffect(() => {
    const stateId = userInfo?.state_id;
    if (stateId) handleCities(Number(stateId) ?? 0);
  }, [userInfo?.state_id]);

  const compressImage = (file: File, quality = 0.7): Promise<Blob | null> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set canvas size to desired dimensions (optional resizing)
          const MAX_WIDTH = 800; // Resize width if image is too large
          const scaleSize = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleSize;

          // Draw the image on canvas
          ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Compress image to Blob (JPEG with reduced quality)
          canvas.toBlob(
            (blob) => resolve(blob),
            "image/jpeg", // or "image/webp" for web-friendly compression
            quality // Compression quality: 0.1 (low) to 1.0 (high)
          );
        };
      };
    });
  };

  const uploadFile = async (file: File) => {
    try {
      const compressedBlob = await compressImage(file, 0.7); // Adjust quality as needed
      if (!compressedBlob) throw new Error("Failed to compress image");
      setUploadProgress(0);

      const formData = new FormData();
      formData.append("profile_pix", compressedBlob, file.name);

      await apiClient.post("upload-profile-pix", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted - 25);
          }
        },
      });

      setUploadProgress(100);

      await getUserInfo();
    } catch (error) {
      console.error(error); // Display error message from apiClient
      setUploadProgress(0); // Reset progress on failure
    }
  };

  const handleCountries = async () => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get("countries");
      setCountries(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  const handleStates = async (country: number) => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get(`countries/${country}/states`);
      setStates(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  const handleCities = async (state: number) => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get(`states/${state}/cities`);
      setCities(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  // Upload or process cropped image
  const handleCroppedImage = async (file: File) => {
    setUploadProgress(0);
    await uploadFile(file);
  };

  // handle-input-change
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any
    >
  ) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value: string) => {
    setUserInfo((prevState: any) => ({
      ...prevState,
      phone_number: value,
    }));
  };

  // handle-update-account
  const handleUpdateAccount = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});
    setServerError("");

    const result = UpdateUserInfoSchema.safeParse(userInfo);

    if (result.success) {
      try {
        setLoading(true);
        const data: any = {
          ...result.data,
          interest: result.data.interest.split(","),
        };

        await apiClient.post("update-user-account", data);
        await getUserInfo();

        successAlert();
      } catch (error: any) {
        console.log(error);
        setServerError(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    } else {
      const formattedErrors: Partial<Record<keyof UpdateUserInfoType, string>> =
        {};

      result.error.errors.forEach((err) => {
        if (err.path.length > 0) {
          const key = err.path[0] as keyof UpdateUserInfoType;
          formattedErrors[key] = err.message;
        }
      });

      setErrors(formattedErrors);
    }
  };

  const getUserInfo = async () => {
    try {
      setLoading(true);
      const res = await apiClient.get(`user-info`);
      storeUser(res.data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <div className="">
        <div className="grid grid-cols-12 gap-8 justify-between w-full">
          {/* side nav with suggestion */}
          <MyProfileSideBar />

          <form
            onSubmit={handleUpdateAccount}
            className="col-span-12 md:col-span-8 lg:col-span-7 bg-hc_F4EBFA dark:bg-slate-700 rounded-10px py-8"
          >
            {/* step 1 */}
            <div className="px-5 bg-white dark:bg-slate-800 py-8 flex-col">
              <div className="flex justify-between items-center mb-4 mt-5">
                <h3 className="text-sm font-semibold dark:text-gray-300">
                  Personal Data
                </h3>
                <p className="text-xs font-medium text-right text-honeConnectGray dark:text-gray-400">
                  1/4
                </p>
              </div>

              <div className="flex flex-col text-sm font-medium mb-4">
                <label className="mb-1.5 dark:text-gray-400">
                  Full Name
                  <span className="text-honeConnectOrange"> (required)</span>
                </label>
                <input
                  value={userInfo?.full_name}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300"
                  placeholder="Enter your Full Name"
                  name="full_name"
                />
                <span className="text-red-600 text-[10px]">
                  {errors.full_name}
                </span>
              </div>

              <div className="flex flex-col text-sm font-medium mb-4">
                <label className="mb-1.5 dark:text-gray-400">
                  Email Address
                </label>
                <div className="relative">
                  <input
                    value={userInfo?.email}
                    type="text"
                    className="disabled:bg-gray-200 w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300"
                    placeholder="Enter your Email Address"
                    name="email"
                    disabled
                  />
                  {user.is_email_verified && (
                    <span className="absolute top-[12px] right-[10px]">
                      <svg
                        width="22"
                        height="21"
                        viewBox="0 0 22 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                          fill="#20B038"
                        />
                      </svg>
                    </span>
                  )}
                </div>
              </div>

              <div className="flex flex-col w-full  mb-4">
                <label className="mb-2 text-sm font-medium dark:text-gray-400">
                  Phone Number
                  <span className="text-honeConnectOrange"> (required)</span>
                </label>
                <div className="relative">
                  <PhoneInput
                    country="ng"
                    value={userInfo.phone_number as string}
                    onChange={handlePhoneChange}
                    inputClass={`${inputStyle} text-xs `}
                    inputProps={{ autoComplete: "off" }}
                  />
                  {user.is_phone_verified && (
                    <span className="absolute top-[12px] right-[10px]">
                      <svg
                        width="22"
                        height="21"
                        viewBox="0 0 22 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                          fill="#20B038"
                        />
                      </svg>
                    </span>
                  )}
                </div>
                <span className="text-red-600 text-xs">
                  {errors.phone_number}
                </span>
              </div>

              <div className="flex flex-col text-sm font-medium mb-4">
                <label className="mb-1.5 dark:text-gray-400">Username</label>
                <input
                  value={userInfo.username}
                  type="text"
                  className="disabled:bg-gray-200 w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300"
                  placeholder="Enter your Instagram id"
                  name="username"
                  disabled
                />
              </div>

              <div className="flex flex-col text-sm font-medium  mb-4">
                <label className="mb-1.5 dark:text-gray-400">
                  DOB
                  <span className="text-honeConnectOrange"> (required)</span>
                </label>
                <input
                  value={userInfo?.DOB}
                  onChange={handleInputChange}
                  type="date"
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300"
                  placeholder="Enter your DOB"
                  name="DOB"
                />
                <span className="text-red-600 text-[10px]">{errors.DOB}</span>
              </div>

              <div className="flex flex-col w-full  mb-4">
                <label className="mb-2 text-sm font-medium dark:text-gray-400">
                  Gender
                  <span className="text-honeConnectOrange"> (required)</span>
                </label>
                <select
                  value={userInfo.gender}
                  name="gender"
                  onChange={handleInputChange}
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                >
                  <option value="">Select Gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHERS">Not Specified</option>
                </select>
                <span className="text-red-600 text-xs">{errors.gender}</span>
              </div>

              <div className="flex flex-col text-sm font-medium mb-4">
                <label className="mb-1.5 dark:text-gray-400">
                  Bio
                  <span className="text-honeConnectOrange"> (optional)</span>
                </label>
                <textarea
                  value={userInfo.bio}
                  onChange={handleInputChange}
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300"
                  placeholder="Enter your Instagram id"
                  name="bio"
                  maxLength={100}
                ></textarea>
                <span className="text-red-600 text-[10px]">{errors.bio}</span>
              </div>

              <div className="flex flex-col text-sm font-medium">
                <label htmlFor="interest" className="mb-1.5 dark:text-gray-400">
                  Select your preferred interest
                </label>
                <select
                  value={userInfo.interest}
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                  name="interest"
                  onChange={handleInputChange}
                >
                  <option value="">Select interest</option>
                  <option value="1">Partner</option>
                  <option value="2">Relationship</option>
                  <option value="3">Dating</option>
                  <option value="4">Love</option>
                  <option value="5">Friend</option>
                  <option value="6">Convo</option>
                  <option value="7">Mingle</option>
                </select>
                <span className="text-red-600 text-[10px]">
                  {errors.interest}
                </span>
              </div>
            </div>

            {/* step 2 */}
            <div className="px-5 bg-white dark:bg-slate-800 py-5 flex-col mt-3">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-sm font-semibold dark:text-gray-300">
                  Location Preference
                </h3>
                <p className="text-xs font-medium text-right text-honeConnectGray dark:text-gray-400">
                  2/4
                </p>
              </div>

              <div className="flex flex-col w-full mb-4">
                <label
                  htmlFor="country"
                  className="mb-2 text-sm font-medium dark:text-gray-400"
                >
                  Country
                </label>
                <select
                  value={userInfo.country_id}
                  name="country_id"
                  onChange={handleInputChange}
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                >
                  <option value="">Select Country</option>
                  {countries?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="text-red-600 text-xs">
                  {errors.country_id}
                </span>
              </div>

              <div className="flex flex-col w-full mb-4">
                <label
                  htmlFor="state"
                  className="mb-2 text-sm font-medium dark:text-gray-400"
                >
                  State
                </label>
                <select
                  value={userInfo.state_id}
                  name="state_id"
                  onChange={handleInputChange}
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                >
                  <option value="">Select State</option>
                  {states?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="text-red-600 text-xs">{errors.state_id}</span>
              </div>

              <div className="flex flex-col w-full mb-4">
                <label
                  htmlFor="city"
                  className="mb-2 text-sm font-medium dark:text-gray-400"
                >
                  City
                </label>
                <select
                  value={userInfo?.city_id}
                  name="city_id"
                  onChange={handleInputChange}
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                >
                  <option value="">Select Closest City</option>
                  {cities?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="text-red-600 text-xs">{errors.city_id}</span>
              </div>

              <div className="flex flex-col w-full mb-4">
                <label
                  htmlFor="area"
                  className="mb-2 text-sm font-medium dark:text-gray-400"
                >
                  Area
                </label>
                <input
                  value={userInfo.area}
                  name="area"
                  type="text"
                  placeholder="Area"
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                  onChange={handleInputChange}
                />
                <span className="text-red-600 text-xs">{errors.area}</span>
              </div>
            </div>

            {/* step 3 */}
            <div className="px-5 bg-white dark:bg-slate-800 py-5 flex-col mt-3">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-sm font-semibold dark:text-gray-300">
                  Social Links
                </h3>
                <p className="text-xs font-medium text-right text-honeConnectGray dark:text-gray-400">
                  3/4
                </p>
              </div>

              <div className="flex flex-col text-sm font-medium mb-4">
                <label htmlFor="IG_id" className="mb-1.5 dark:text-gray-400">
                  Instagram ID
                  <span className="text-honeConnectOrange"> (optional)</span>
                </label>
                <input
                  value={userInfo.instagram_id}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300"
                  placeholder="Enter your Instagram id"
                  name="instagram_id"
                />
                <span className="text-red-600 text-[10px]">
                  {errors.instagram_id}
                </span>
              </div>

              <div className="flex flex-col text-sm font-medium mb-4">
                <label htmlFor="snapID" className="mb-1.5 dark:text-gray-400">
                  Snapchat ID
                  <span className="text-honeConnectOrange"> (optional)</span>
                </label>
                <input
                  value={userInfo.snapchat_id}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300"
                  placeholder="Enter your snapchat id"
                  name="snapchat_id"
                />
                <span className="text-red-600 text-[10px]">
                  {errors.snapchat_id}
                </span>
              </div>

              <div className="flex flex-col text-sm font-medium mb-4">
                <label htmlFor="x_ID" className="mb-1.5 dark:text-gray-400">
                  X ID
                  <span className="text-honeConnectOrange"> (optional)</span>
                </label>
                <input
                  value={userInfo.twitter_x_id}
                  onChange={handleInputChange}
                  type="text"
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300"
                  placeholder="Enter your X id"
                  name="twitter_x_id"
                />
                <span className="text-red-600 text-[10px]">
                  {errors.twitter_x_id}
                </span>
              </div>
            </div>

            {/* step 4 */}
            <div className="px-5 bg-white dark:bg-slate-800 py-5 flex-col mt-3 pb-[250px]">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-sm font-semibold dark:text-gray-300">
                  Upload Profile Image
                </h3>
                <p className="text-xs font-medium text-right text-honeConnectGray dark:text-gray-400">
                  4/4
                </p>
              </div>

              <div className="flex flex-col text-center justify-center text-sm font-medium">
                <ImageCrop onImageSelect={handleCroppedImage} />

                {/* <p>Upload a picture</p> */}
                {uploadProgress > 0 && (
                  <div className="flex justify-center items-center gap-1 mt-2">
                    <p>{uploadProgress}%</p>

                    <div
                      className={`h-[4px] flex w-full ${
                        uploadProgress === 100 ? "bg-green-700" : "bg-[#ffb7a0]"
                      } rounded-lg`}
                    >
                      <div
                        className={`h-[4px] ${
                          uploadProgress === 100
                            ? "bg-green-700"
                            : "bg-honeConnectOrange"
                        } rounded-lg`}
                        style={{ width: uploadProgress + "%" }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="fixed z-[2] bottom-16 border-t shadow bg-white dark:bg-slate-800 right-0 left-0 px-6 py-3 dark:border-slate-700">
              <p className="text-red-600 text-[10px] mb-1 xl:w-1/2 text-center z-3">
                {serverError}
              </p>
              <button
                type="submit"
                className="bg-gray-100 dark:bg-slate-800 dark:text-gray-300 text-gray-700 w-full p-3 rounded-lg border mb-10 shadow-sm font-medium"
                disabled={loading}
              >
                {loading ? "Loading..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </AppLayout>
  );
};

export default EditProfile;
