import { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useNavigate, useParams } from "react-router-dom";
import apiClient from "../api-services/ApiClient";
import { getUser, PAYSTACK_PUBLIC_KEY } from "../api-services/process";
import Preloader from "../app-components/preloader/Preloader";
import { SITELINKS } from "../app-routes/links";
import { UserProfileInterface } from "../app-schemas/user.interface";
import { errorAlert, successAlert } from "./SweetAlert";

const PaymentButton: React.FC<{
  amount: number;
  plan: string;
  duration: number;
  btnLabel: string;
}> = ({ amount, plan, duration, btnLabel }) => {
  const navigate = useNavigate();

  const user: UserProfileInterface = getUser();
  const { id } = useParams() || {};
  const [loading, setLoading] = useState(false);

  const config = {
    reference: user?.id + new Date().getTime().toString(),
    email: user?.email,
    amount: amount * 100,
    publicKey: PAYSTACK_PUBLIC_KEY || "",
  };

  const initializePayment = usePaystackPayment(config);

  const handleConnect = async (uuid: any) => {
    if (!uuid) return;

    const data = {
      connection_request_id: uuid,
    };

    try {
      setLoading(true);
      const res = await apiClient.post("connection-requests-responses", data);
      successAlert();

      if (res.data.status) {
        navigate(SITELINKS.MY_RESPONSES);
      }
    } catch (error: any) {
      if (error.match("NO_PLAN")) {
        navigate(`${SITELINKS.SUBSCRIPTIONS}/${uuid}`);
      } else {
        errorAlert(JSON.stringify(error), 20000);
      }
    } finally {
      setLoading(false);
    }
  };

  const verifyPayment = async (reference: any) => {
    try {
      setLoading(true);
      const res = apiClient.get(`verify-transaction/${reference}`);

      if (id && (await res).data.status) handleConnect(id);
      else successAlert();

    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const payWithPayStack = async () => {
    try {
      initializePayment({
        onSuccess: (reference) => {
          verifyPayment(reference.reference);
        },
        onClose: () => {
          console.log("Payment closed.");
          setLoading(false);
          // verifyPayment(reference);
        },
      });
    } catch (error) {
      console.error("Error during payment initialization:", error);
      setLoading(false);
      // verifyPayment(reference);
      alert(
        "An error occurred while processing the payment. Please try again."
      );
    }
  };

  const makeSubscription = async () => {
    if (amount === 0) return;

    try {
      setLoading(true);
      const data = {
        connection_request_uuid: id,
        amount: amount,
        reference: config.reference,
        duration: duration,
        plan: plan,
      };
      const res = await apiClient.post("make-subscription", data);
      if (res.data.payment_reference === config.reference) {
        payWithPayStack();
      }
    } catch (err) {
      errorAlert();
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={makeSubscription}
        className="paystack-button paystack-button bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-base mt-4 mb-6 font-medium"
      >
        {btnLabel}
      </button>

      <Preloader loading={loading} />
    </>
  );
};

export default PaymentButton;
