import WebsiteHeader from "./website-components/WebsiteHeader";
import WebsiteFooter from "./website-components/WebsiteFooter";

const PrivacyPolicy = () => {
  return (
    <main>
      <WebsiteHeader />
      {/* HERO SECTION */}
      <div className="bg-[#F8FAFC]" id="home">
        <div className="w-11/12 mx-auto py-10 md:py-16 md:pb-16 grid md:grid-rows-2 lg:grid-rows-none lg:grid-cols-2 gap-10">
          <div className="pr-0 lg:pr-20 lg:text-left">
            <h1 className="text-[30px] md:text-[55px] font-bold mb-5 md:mb-8 lg:mb-4 leading-tight text-left">
              Terms <span className="text-honeConnectPurple"> </span> And{" "}
              <span className="text-honeConnectPurple">Conditions</span>{" "}
            </h1>

            <div className="flex flex-col">
              <h1>Privacy Policy</h1>

              <h2>1.0 Introduction</h2>
              <p>
                This Privacy policy describes how Hone Connect Ltd, a private
                company limited by shares, incorporated in the Federal Republic
                of Nigeria with registration number 7570392 will process your
                Personal data in a compliant, ethical and secure manner.
              </p>
              <p>
                This privacy policy applies to any person(s) who uses the Hone
                Connect web application.
              </p>

              <h2>2.0 Definitions</h2>
              <p>
                <strong>2.1 Personal Data:</strong> Means information collected
                from a Subject including but not limited to, name; contact
                information (e-mail address, residential address, postal
                address, telephone number); location data; online identifier
                (MAC address, IP address etc.); biometric information;
                correspondences; views and opinions.
              </p>
              <p>
                <strong>2.2 Subject:</strong> Means a person who can be
                identified directly or indirectly by one or more factors
                specific to the physical, physiological, genetic, mental,
                economic, cultural or social identity of that person.
              </p>

              <h2>3.0 Collected and Processed Data</h2>
              <ul>
                <li>
                  <strong>3.1 Account and Contact Information:</strong> This may
                  include name; contact information (e-mail address, residential
                  address, postal address, telephone number); date of birth;
                  identifying numbers; billing information; any other
                  information required to comply with regulatory requirements.
                </li>
                <li>
                  <strong>3.2 Location Information:</strong> This may include
                  information regarding your physical location/geolocation when
                  you connect to the Hone Connect web application.
                </li>
                <li>
                  <strong>3.3 Financial Information:</strong> This may include
                  information such as your bank account or credit card details.
                </li>
                <li>
                  <strong>3.4 Web Browsing and Application Usage:</strong> This
                  may include your personal data when visiting our web
                  application such as IP address; browser type; operating
                  system; mobile carrier; ISP; platform technology; time spent
                  on our mobile application; links or advertisements; purchases
                  made through our web application; first-party cookies and
                  other web-tracking technologies.
                </li>
                <li>
                  <strong>3.5 Multimedia Data:</strong> This includes but is not
                  limited to photographs, audio recordings and video recordings.
                </li>
                <li>
                  <strong>3.6 Social Media Information:</strong> This includes
                  but is not limited to interactions with our social media
                  accounts, content and marketing campaigns, personal data
                  available from your public profile and information you have
                  allowed us to share based on your privacy preferences on our
                  platform and other social media handles.
                </li>
                <li>
                  <strong>3.7 Biometric Information:</strong> This includes but
                  is not limited to images, fingerprints, voice recordings.
                </li>
                <li>
                  <strong>3.8 Demographic Information:</strong> This may be
                  information related to gender, race, age.
                </li>
                <li>
                  <strong>3.9 Views, Opinions and Interests:</strong> This may
                  be information relating to views, opinions, preferences,
                  interests.
                </li>
                <li>
                  <strong>3.10 Cookies:</strong> These are required for
                  navigation and performing certain functions.
                </li>
              </ul>

              <h2>4.0 Data Usage</h2>
              <ul>
                <li>
                  <strong>4.1 Verification:</strong> Your personal data is used
                  when accessing our web application or making purchases via our
                  web application.
                </li>
                <li>
                  <strong>4.2 Service:</strong> Your personal data is processed
                  in order for us to provide our services to you.
                </li>
                <li>
                  <strong>4.3 Billing:</strong> Your personal data is processed
                  in order to perform billing on your account.
                </li>
                <li>
                  <strong>4.4 Monitoring & Improvement:</strong> Your personal
                  data is processed in order to improve and optimize our
                  service.
                </li>
                <li>
                  <strong>4.5 Performance:</strong> Your personal data is
                  processed to evaluate, maintain and improve our IT
                  performance.
                </li>
                <li>
                  <strong>4.6 Marketing:</strong> Your personal data may be
                  processed for promotions and marketing purposes in accordance
                  with the laws and regulations.
                </li>
                <li>
                  <strong>4.7 Compliance and Reporting:</strong> Your personal
                  data is processed in order to comply with the laws and
                  regulations.
                </li>
              </ul>

              <h2>5.0 Data Retention/Sharing</h2>
              <p>
                Personal data will be retained according to the local laws and
                regulations.
              </p>
              <p>
                Your personal data may be shared with our third-party service
                providers, such as IT systems, hosting providers, consultants,
                and other goods and service providers.
              </p>
              <p>
                Your personal data may be shared in response to requests to
                courts, tribunals, government, or law enforcement agencies.
              </p>
              <p>Your personal data may be required for audit purposes.</p>

              <h2>6.0 Data Security</h2>
              <p>
                Hone Connect Ltd secures the integrity and confidentiality of
                the personal data in its possession while implementing
                appropriate, reasonable technical, and organizational measures
                to prevent accidental loss or damage of personal data, unlawful
                or unauthorized access to your personal data, unlawful or
                unauthorized processing of your data.
              </p>
              <p>
                Hone Connect Ltd will implement reasonable measures to prevent
                or reduce the likelihood of personal data breach.
              </p>
            </div>
          </div>
        </div>
      </div>
      <WebsiteFooter />
    </main>
  );
};

export default PrivacyPolicy;
