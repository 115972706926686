import React from "react";
// bg-gray-800 dark:bg-slate-800
const Preloader = ({ loading }: { loading: boolean }) => {
  return loading ? (
    <div className="fixed inset-0 bg-gray-800 dark:bg-slate-800 bg-opacity-50 dark:bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-slate-800 p-3 px-5 rounded-lg flex flex-col items-center">
        <div className="rounded-full border-2 border-gray-300 border-t-2 border-t-purple-500 h-12 w-12 mb-4 animate-spin"></div>
        <p className="text-gray-500 dark:text-slate-200 text-[10px] font-medium">
          Loading...
        </p>
      </div>
    </div>
  ) : null;
};

export default Preloader;
